import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Form from './Form';
import ResetPassword from './ResetPassword';
import Home from './Home';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/upload-data" element={<Form />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App;
