import React from 'react'
import { useState } from 'react';

const Form = () => {
    const [email, setEmail] = useState('')
    const [pw, setPw] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)

    const handleUpload = async (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('fileInput');
        const file = fileInput.files[0];
        if (!file) {
            alert("Please select a file");
            return;
        }

        const formData = new FormData()
        formData.append('file', file)
        
        await fetch('/api/data/upload', {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            const contentType = res.headers.get('Content-Type')
            if (contentType && contentType.includes('application/json')) { 
                return res.json()
            } else { 
                return res.text()
            }
        }).then(data => {
            alert('File uploaded.')
        }).catch(err => {
            console.error(err)
            alert('Upload failed.')
        })
    };

    const handleLogin = async (e) => {
        e.preventDefault()
        if(!email && !pw) {
            alert('Enter login details.')
            return
        }
        await fetch('/api/auth/login', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: pw
            })
        }).then(res => {
            if(res.status === 401) {
                alert('Incorrect login details.')
            }
            return res.json()
        }).then(data => {
            localStorage.setItem('token', data.accessToken)
            setLoggedIn(true)
        })
    }

    return (
        <div className="App">
            <header className="App-header">
                { loggedIn ? <>
                    <input type="file" name="file" id="fileInput" />
                    <button onClick={handleUpload}>Upload</button>
                </> : <form>
                    <input type='text' name='email' id='emailInput' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' />
                    <input type='password' name='pw' id='pwInput' value={pw} onChange={e => setPw(e.target.value)} placeholder='Password' />
                    <button onClick={handleLogin}>Login</button>
                </form> }
            </header>
        </div>
    )
}

export default Form