import React, { useState, useEffect } from 'react'

const ResetPassword = () => {
	const [pw, setPw] = useState('')
	const [confirmPw, setConfirmPw] = useState('')
	const [tokenValid, setTokenValid] = useState(false)
	const token = window.location.href.split('/')[4]

	const submitPw = async (e) => {
		try {
			e.preventDefault()
			if (pw !== confirmPw) {
				alert('Passwords do not match')
			}
			await fetch(`http://localhost:5000/api/auth/reset-password/${token}`, {
				method: "POST",
				body: JSON.stringify({ password: pw }),
				headers: { 'Content-Type': 'application/json' }
			}).then(res => {
				if(res.status !== 200) {
					alert('Token invalid/expired.')
				} else {
					alert('Password has been reset.')
				}

				setPw('')
				setConfirmPw('')
			})
		} catch (err) {
			console.error(err)
		}
	}

	const checkToken = async () => {
		await fetch(`http://localhost:5000/api/auth/check-reset-token/${token}`, {
			method: "GET"
		}).then(res => {
			if (res.status !== 200) {
				alert('Token invalid/expired')
				setTokenValid(false)
				return
			} else {
				setTokenValid(true)
			}
		})
	}

	useEffect(() => {
		checkToken()
	}, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
			{tokenValid ? <form style={{ display: 'flex', flexDirection: 'column', rowGap: 9, width: '90%' }} onSubmit={submitPw}>
				<input placeholder='New Password' type='password' style={{ padding: 9, borderRadius: 9, borderColor: '#505050', borderWidth: 2, borderStyle: 'solid' }} value={pw} onChange={e => setPw(e.target.value)} />
				<input placeholder='Confirm New Password' type='password' style={{ padding: 9, borderRadius: 9, borderColor: '#505050', borderWidth: 2, borderStyle: 'solid' }} value={confirmPw} onChange={e => setConfirmPw(e.target.value)} />
				<button style={{ padding: 9, borderRadius: 9, borderColor: '#505050', borderWidth: 2, borderStyle: 'solid' }}>Change password</button>
			</form> : <p>Token invalid</p>}
		</div>
    )
}

export default ResetPassword